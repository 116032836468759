import { StudentReminders } from '../../types/global'

export interface TReviewFormated {
  ongoingChallenges: Array<StudentReminders>
  pendingReviews: Array<StudentReminders>
  allReviews: Array<StudentReminders>
}

export const reviewsFormater = (reviews: StudentReminders[]): TReviewFormated => {
  const result = {
    ongoingChallenges: [],
    pendingReviews: [],
    // removes accepted challenges from all reviews
    allReviews: reviews.filter(
      review => !(review.status === 'accepted' && review.content_type === 'ContentAction')
    )
  } as TReviewFormated

  // fill review types
  reviews.forEach(review => {
    if (review.content_type === 'ContentAction') {
      if (review.status === 'accepted') {
        result.ongoingChallenges.push(review)
      } else {
        result.pendingReviews.push(review)
      }
    } else {
      if (review.pending) {
        result.pendingReviews.push(review)
      }
    }
  })

  return result
}
