import React, { Dispatch as ReactDispatch } from 'react'
import { StudentParameters } from '../../types/parameters'
import { domoscioAccess } from '../../api/domoscio_access'
import { updateUserJWT } from '../../storage/jwt'

// Context
// Create Contexts
const State = React.createContext<Partial<StudentParameters>>({})
const Dispatch = React.createContext<ReactDispatch<Partial<StudentParameters>>>(() => {})

const init: Partial<StudentParameters> = {} // You can define the initial state values here if needed

// Reducer
const reducer = (state: Partial<StudentParameters>, nextState: Partial<StudentParameters>) => {
  const newState = {
    ...state,
    ...nextState
  } as Partial<StudentParameters>

  if (Object.keys(nextState)[0] === 'onboarding') {
    domoscioAccess.updateUserParameter({ onboarding: nextState.onboarding })
  }
  updateUserJWT(newState)

  return newState
}

// Provider component
interface ProviderProps {
  children: React.ReactNode
}

// Provider
const Provider: React.FC<ProviderProps> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, init)

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  )
}

// Export
export const User = {
  State,
  Dispatch,
  Provider
}
