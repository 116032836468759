import { Content } from '../../hooks/useLearningSession'
import { UserAnswer } from '../../screens/learning_session/LearningSession'
import { PostLs } from '../../types/global'
import { domoscioAccess } from '../domoscio_access'
import { Payload, payloadOptions } from './payload'

type EventBody = {
  platform: string
  standard: 'xAPI'
  content_id: string | number
  event_type: 'EventReview'
  time_spent?: number
  content_type: string
  review_id: string | number
  learning_session_id: string | number
  payload: string
  expected_at: string
}

type Params = {
  input: UserAnswer
  // eslint-disable-next-line
  content: any
  learningSession: PostLs
}

const xAPIPayload = (input: UserAnswer, content: Content): Payload => {
  const payload = {
    platform: 'lxp-web',
    timestamp: new Date().toISOString(),
    id: null,
    verb: {
      id: 'http://adlnet.gov/expapi/verbs/scored'
    },
    object: {
      objectType: content.type
    },
    result: {
      answer: input.answers,
      score: {
        scaled: input.score
      }
    }
  }
  return payload
}

const setParams = (params: Params, closeAction: boolean = false): EventBody => {
  const { input, content, learningSession } = params

  const payload = {
    ...xAPIPayload(input, content),
    ...payloadOptions(input, content, closeAction)
  }

  return {
    standard: 'xAPI',
    platform: 'lxp-web',
    payload: JSON.stringify(payload),
    event_type: 'EventReview',
    time_spent: input.timespent,
    content_type: content.type,
    content_id: content.id,
    review_id: content.review.id,
    learning_session_id: learningSession.id,
    expected_at: new Date().toISOString()
    // objective_id: learningSession.objective_id,
  }
}

/**
 * @category API
 * @async
 */
export const postEventReview = async (params: Params): Promise<object> => {
  const body = setParams(params)
  return await domoscioAccess.postEvent(body)
}

/**
 * @category API
 * @async
 */
export const closeMicroAction = async (params: Params): Promise<object> => {
  const body = setParams(params, true)
  return await domoscioAccess.postEvent(body)
}
