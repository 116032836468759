import React, { createContext, SetStateAction, useContext, useState } from 'react'
import { MemoLevels, ObjectiveStudent } from '../../components/home/progress/types'
interface ProgressContextType {
  progress: MemoLevels | null
  setProgressData: React.Dispatch<SetStateAction<ContextValues>>
  objectives: ObjectiveStudent[] | null
}

interface ContextValues {
  progress: MemoLevels | null
  objectives: ObjectiveStudent[] | null
}

export const ProgressContext = createContext<ProgressContextType | undefined>(undefined)

export const useProgress = () => {
  const context = useContext(ProgressContext)
  if (!context) {
    throw new Error('useProgress must be used within an ProgressProvider')
  }
  return context
}
interface Props {
  children: React.ReactNode
}

export const ProgressProvider = ({ children }: Props) => {
  const [progressData, setProgressData] = useState<ContextValues>({
    objectives: null,
    progress: null
  })

  return (
    <ProgressContext.Provider
      value={{
        progress: progressData.progress,
        objectives: progressData.objectives,
        setProgressData
      }}
    >
      {children}
    </ProgressContext.Provider>
  )
}
