import React, { useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { Question } from '@domoscio/domoscio-ui'
import Slideshow from '../../components/correction/Slideshow'

import '../../styles/LearningSession.scss'
import '../../styles/LearningSessionResult.scss'

/**
 * @category Screens
 * /learning_sessions/result/correction
 */
const Correction = () => {
  // Hooks
  const navigate = useNavigate()
  const location = useLocation()
  const { from, results, activeIndex } = location.state || { from: '', results: [], activeIndex: 0 }

  useEffect(() => {
    // no reviews return to home
    if (results.length === 0) {
      navigate('/')
    }
    // eslint-disable-next-line
  }, [])

  // Exit screen function
  const exit = () => navigate(`/${from}/result`, { state: location.state })

  // Renderers
  return (
    <div className={`LearningSession Correction ${from}`}>
      {/* Navigation */}
      <div className='header'>
        <div className='close' onClick={exit}>
          <MdClose size={40} color='#777' />
        </div>
      </div>
      <Slideshow startAt={activeIndex}>
        {results.map((result, index: number) => (
          <section key={index} className='container'>
            <Question
              {...result.props}
              userInput={result.input.answers}
              correctionScore={result.input.score}
              isCorrectionMode={true}
            />
          </section>
        ))}
      </Slideshow>
    </div>
  )
}

export default Correction
