import { domoscioAccess } from '../api/domoscio_access'
import { ObjectiveStudent } from '../components/home/progress/types'

// Get objective scoped knowledge_nodes infos
export async function getAverageMemoryLevel(
  objectives: ObjectiveStudent[] | number[],
  isTsr?: boolean
) {
  const objectiveIds = isTsr ? objectives : objectives.map(el => el.objective_id)

  return await domoscioAccess.getAverageMemoryLevel({
    objective_id: objectiveIds,
    group_by: ['objective_id', 'knowledge_node_id']
  })
}

// Get student scoped objective_students infos
export async function getObjectiveStudents(studentUid: string) {
  return await domoscioAccess.getObjectiveStudents({
    student_uid: studentUid
  })
}
