/**
 * @file Manages the methods of the ProgressScreen screen.
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import I18n from '../../locales/I18n'
import { domoscioAccess } from '../../api/domoscio_access'
import { User } from '../../contexts/parameters/parametersWrapper'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { useScormUser } from '../../hooks/useScormUser'
import { TLoading } from '../../types/global'
import ComponentErrorWrapper from '../../components/Errors/ErrorWrappers/ComponentErrorWrapper'
import { Loader } from '@domoscio/domoscio-ui'
import { useDomToolkit } from '../../styles/dom-toolkit'
/**
 * Renders the Progress Screen
 *
 * @category Screens
 */

type Tbody = Partial<{ locale: string; tour_guide: boolean; objective_ids: Array<number> | number }>
function ProgressScreen() {
  const [status, setStatus] = React.useState<TLoading>('idle')
  const [isNew] = React.useState(false)
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)
  const showTour = !(user?.onboarding?.lxp_lock?.progress ?? false)
  const replayTour = user.replayTour?.progress ?? false
  const [chartUrl, setChartUrl] = React.useState(null)
  const [iframeLoading, setIframeLoading] = React.useState(true)
  const classes = useDomToolkit()

  const learningProgramIds = useScormUser()

  // Load chart onMount
  React.useEffect(() => {
    fetchIframeUrl()
    // eslint-disable-next-line
  }, [])

  // Reload chart with tour if context user progress tour change
  React.useEffect(() => {
    if (!isNew) {
      if (showTour) {
        fetchIframeUrl()
        userDispatch({
          onboarding: domUtils.updateTour(user.onboarding, 'lxp_lock', {
            progress: true
          })
        })
      } else if (replayTour) {
        fetchIframeUrl()
        userDispatch({ replayTour: { progress: false } })
      }
    }
    // eslint-disable-next-line
  }, [showTour, replayTour])

  // Get chart url method

  const fetchIframeUrl = () => {
    setStatus('loading')
    // if scorm user first fetch objectives of learning program than progression chart
    const isLpIdExist = learningProgramIds.learning_program_id
    if (isLpIdExist) {
      domoscioAccess
        .getObjectives({ learning_program_id: [isLpIdExist] })
        .then(objectives => {
          if (Array.isArray(objectives)) {
            const objectiveIds = objectives.map(elem => elem.id)
            loadMemorizeProgressionChart(objectiveIds)
          } else {
            setStatus('error')
          }
        })
        .catch(() => setStatus('error'))
      // not scorm user fetch progression chart
    } else {
      loadMemorizeProgressionChart()
    }
  }

  function loadMemorizeProgressionChart(objectiveIds?: Array<number> | number) {
    const body = {
      locale: I18n.locale,
      tour_guide: showTour || replayTour
    } as Tbody
    // scope graph for scorm users
    if (objectiveIds) {
      body.objective_ids = objectiveIds
    }
    domoscioAccess
      .getMemorizeProgressionChart(body)
      .then(response => {
        if (response.url) {
          setChartUrl(response.url)
        } else {
          setStatus('error')
        }
      })
      .catch(() => setStatus('error'))
  }

  if (status === 'error') {
    return <ComponentErrorWrapper onClickRefresh={fetchIframeUrl} />
  }

  return (
    <>
      <main className={`ProgressScreen ${classes.domHeight100}`}>
        {chartUrl ? (
          <>
            <iframe
              src={chartUrl}
              onLoad={() => setIframeLoading(false)}
              sandbox='allow-same-origin allow-scripts allow-popups allow-forms allow-downloads'
              height='100%'
              width='100%'
              style={{
                display: iframeLoading ? 'none' : 'initial'
              }}
            />
            {iframeLoading && <Loader />}
          </>
        ) : (
          <Loader />
        )}
      </main>
    </>
  )
}

export default ProgressScreen
