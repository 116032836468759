import { DomoscioAccess } from '@domoscio/domoscio-sdk-js'
import { getStorage } from '../../storage'
// import * as Sentry from '@sentry/react'

const storage = getStorage()
const access = storage.getItem('accesstoken')
const endpoint = process.env.REACT_APP_DOMOSCIO_ACCESS_URL
const env = process.env.REACT_APP_DOMOSCIO_ENV || 'dev'

const devMode = env === 'dev'

function reportingManager(error: Error, body: { [key: string]: string }) {
  console.log(error)
  console.log(body)
}

export const domoscioAccess = new DomoscioAccess(
  access,
  endpoint,
  reportingManager,
  devMode,
  'student'
)
