import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Question, Loader } from '@domoscio/domoscio-ui'
import { Content, useLearningSession, useNextQuestion } from '../../hooks/useLearningSession'
import { postEventReview } from '../../api/adaptive_engine/events'
import * as Errors from '../../components/Errors/ErrorScreens'
import '../../styles/LearningSession.scss'
import { useRiseUpClient } from '../../hooks/useRiseUpClient'
import { useProgress } from '../../contexts/progressData/progressData'
import { StudentReminders } from '../../types/global'

const getQuestionProps = (content: Content) =>
  Question.converter.qti2js((content as { qti: string }).qti)
interface LocationState {
  reviews: StudentReminders[]
}

export interface UserAnswer {
  score: number
  answers: string | number[]
  timespent?: number
}
export interface Result {
  input: UserAnswer
  content: Content
  props: ReturnType<typeof getQuestionProps>
  courseName: string | undefined
}
/**
 * @category Screens
 * /learning_sessions
 */
const LearningSession = () => {
  // State initialization
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [processing, setProcessing] = useState<boolean>(false)
  const [results, setResults] = useState<Result[]>([])
  const [studiedObjectives, setStudiedObjectives] = useState<number[]>([])

  // Hooks
  const navigate = useNavigate()
  const location = useLocation()
  const { isRiseup } = useRiseUpClient()
  const { progress } = useProgress()
  const { reviews } = (location.state || { reviews: [] }) as LocationState

  const { learningSession, closeLearningSession } = useLearningSession()
  const { contents, isLoading, prepareNextContent, error } = useNextQuestion(reviews, activeIndex)

  useEffect(() => {
    // no reviews return to home
    if (reviews.length === 0) {
      navigate('/')
    }
    // eslint-disable-next-line
  }, [])

  // Constants
  const content = contents[activeIndex]

  // Exit screen function
  const exit = () => {
    if (results.length === 0) {
      navigate('/')
    } else {
      navigate('/learning_sessions/result', {
        state: { results: results, length: reviews.length, studiedObjectives }
      })
    }
  }

  // <Question.onSubmit />
  const handleSubmit = async (answer: UserAnswer) => {
    setProcessing(true)

    // Store results
    const result = {
      input: answer,
      content: content,
      props: getQuestionProps(content),
      courseName: isRiseup ? getCourseName() : undefined
    }
    setResults([...results, result])

    // Preload next question
    prepareNextContent().then(() => setProcessing(false))

    // push objective id if not exist in the array of studied objectives

    if (isRiseup && typeof getObjectiveIdOfQuestion() === 'number') {
      if (!studiedObjectives.includes(Number(getObjectiveIdOfQuestion()))) {
        const newStudiedObjectiveList = [...studiedObjectives]
        newStudiedObjectiveList.push(Number(getObjectiveIdOfQuestion()))

        setStudiedObjectives(newStudiedObjectiveList)
      }
    }
    // Post related event
    learningSession && postEventReview({ ...result, learningSession: learningSession })
  }

  // <Question.onNextAction />
  const handleNextAction = async () => {
    const nextIndex = activeIndex + 1
    if (nextIndex === reviews.length) {
      closeLearningSession()
      exit()
    } else {
      setActiveIndex(activeIndex + 1)
    }
  }

  const getObjectiveIdOfQuestion = (): number | undefined => {
    const objectiveIds = content?.review?.objective_ids || []
    return progress ? objectiveIds.filter(id => progress[id])[0] : undefined
  }

  const getCourseName = () => {
    const objectiveId = getObjectiveIdOfQuestion()
    return objectiveId && progress ? progress[objectiveId].name : undefined
  }

  // Renderers
  return !content ? null : (
    <div className='LearningSession' data-index={activeIndex}>
      <Question.QuizHeader
        courseName={isRiseup ? getCourseName() : undefined}
        title={getQuestionProps(content).concept}
        onCancel={exit}
        progress={{ value: activeIndex + 1, scale: reviews.length }}
      />
      {error ? (
        <div style={{ marginTop: '10%' }}>
          <Errors.ErrorScreen fill='gray' />
        </div>
      ) : (
        <section className='container'>
          {isLoading || learningSession === null ? (
            <Loader />
          ) : (
            <Question
              {...getQuestionProps(content)}
              heading={getQuestionProps(content).concept}
              key={activeIndex}
              onSubmit={handleSubmit}
              onNextAction={handleNextAction}
              awaitingCallback={processing}
            />
          )}
        </section>
      )}
    </div>
  )
}

export default LearningSession
