import React, { createContext, useContext, useState, useEffect } from 'react'
import { domoscioAccess } from '../../api/domoscio_access'
import { reviewsFormater, TReviewFormated } from './utils'
import { useScormUser } from '../../hooks/useScormUser'
import { StudentReminders, TLoading } from '../../types/global'
import { reportToSentry } from '../../components/Errors/reporting'

interface ReviewsContextType {
  reviews: TReviewFormated
  status: TLoading
  fetchReviews: () => void
}

const defaultReviewState = {
  ongoingChallenges: [], // accepted challenges
  pendingReviews: [], // reviews to do today
  allReviews: []
} as TReviewFormated

const ReviewsContext = createContext<ReviewsContextType | undefined>(undefined)

export const ReviewsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [reviews, setReviews] = useState<TReviewFormated>(defaultReviewState)
  const [status, setStatus] = useState<TLoading>('idle')

  const scopedList = useScormUser()

  const fetchReviews = () => {
    setStatus('loading')
    domoscioAccess.getNextReviews(scopedList).then((reviewList: StudentReminders[]) => {
      if (Array.isArray(reviewList)) {
        setReviews(reviewsFormater(reviewList))
        setStatus('success')
      } else {
        reportToSentry({
          name: 'Fetch Error',
          description: 'Failed to fetch reviews'
        })
        setStatus('error')
      }
    })
  }

  useEffect(() => {
    fetchReviews()
    // eslint-disable-next-line
  }, [])

  return (
    <ReviewsContext.Provider value={{ reviews, status, fetchReviews }}>
      {children}
    </ReviewsContext.Provider>
  )
}

export const useHomeReviews = () => {
  const context = useContext(ReviewsContext)
  if (!context) {
    throw new Error('useReviews must be used within a ReviewsProvider')
  }
  return context
}
