import * as Sentry from '@sentry/react'

interface Targs {
  name: string
  description: string
}

export const reportToSentry = ({ name, description }: Targs) => {
  Sentry.withScope(scope => {
    // Set the transaction name
    scope.setTransactionName(name)
    // Capture the exception
    Sentry.captureException(new Error(description))
  })
}
