import React from 'react'
import { Client } from './client'
import { Session } from './session'
import { Theme } from './theme'
import { User } from './user'

const Parameters = ({ children }) => (
  <Client.Provider key='client'>
    <Session.Provider key='session'>
      <Theme.Provider key='theme'>
        <User.Provider key='user'>{children}</User.Provider>
      </Theme.Provider>
    </Session.Provider>
  </Client.Provider>
)

export { Parameters, Client, Session, Theme, User }
