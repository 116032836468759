import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import * as Errors from '../ErrorScreens'
import * as Sentry from '@sentry/react'
import { Theme } from '../../../contexts/parameters/parametersWrapper'

interface AuxProps {
  children: React.ReactNode
}

function ErrorFallback({ error }: { error: string }) {
  const theme = React.useContext(Theme.State)
  const primaryColor = theme?.domBgPrimary
  Sentry.captureException(error)
  return <Errors.ErrorScreen fill={primaryColor || '#005eb8'} />
}

const GlobalErrorWrapper = ({ children }: AuxProps) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
)

export default GlobalErrorWrapper
