/**
 * @file Manages the Reactjs Container and init methods.
 * @author Matthieu Mugnier
 */

import './core/App.css'

import * as React from 'react'
import Navigation from './navigation/Navigation'
import Initializers from './core/Initializers'
import { Parameters } from './contexts/parameters/parametersWrapper'
import { useLocation } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import GlobalErrorWrapper from './components/Errors/ErrorWrappers/GlobalErrorWrapper'
import { ProgressProvider } from './contexts/progressData/progressData'

function App() {
  const { pathname } = useLocation()

  // 👇  To have a gray bg color on download screen
  const isDownloadMode = pathname.includes('download_mobile') || pathname.includes('onboarding')

  return (
    <GlobalErrorWrapper>
      <Parameters>
        <ProgressProvider>
          <Initializers>
            <div className={`App domScroll ${isDownloadMode ? 'downloadMode' : ''}`}>
              <Navigation />
              <Toaster />
            </div>
          </Initializers>
        </ProgressProvider>
      </Parameters>
    </GlobalErrorWrapper>
  )
}

export default App
