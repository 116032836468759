import I18n from 'i18n-js'

import en from './en.json'
import fr from './fr.json'
import es from './es.json'
import nl from './nl.json'

import { domLocales } from '@domoscio/domoscio-sdk-js'

I18n.fallbacks = true
I18n.translations = {
  en,
  fr,
  es,
  nl
}

// Merge src & domLocales translations
I18n.translations = I18n.extend(I18n.translations || {}, domLocales)

I18n.domoscio = ['en', 'fr', 'es', 'nl'] as string[]

export default I18n
