/**
 * @file Display Application Fallback screen
 */

import * as React from 'react'
import { useDomToolkit } from '../../../styles/dom-toolkit'
import Logo from '../../../assets/errors/logo_domoscio.svg'
import { DomButton } from '@domoscio/domoscio-ui'
import './errors.css'
import I18n from '../../../locales/I18n'

/**
 * Renders the Fallback Screen
 *
 * @category Screens
 */
function FallbackScreen() {
  const classes = useDomToolkit()

  function redirectToWebsite() {
    window.location.href = 'https://domoscio.com/fr/'
  }

  function redirectToDocumentation() {
    window.location.href = 'https://developers.domoscio.com/'
  }

  return (
    <div className={`${classes.domHeight100} ${classes.domPositionRelative}`}>
      <div className={`fallbackContainer ${classes.domTextCenter}`}>
        <img className='logoDomoscio' src={Logo} />
        <div className={`${classes.domFlexPerfect} ${classes.domMarginLargeTop}`}>
          <DomButton onClick={redirectToWebsite}>
            {I18n.t('errors.fallback.corporate_site')}
          </DomButton>
        </div>
        <div className={`${classes.domFlexPerfect} ${classes.domMarginLargeTop}`}>
          <DomButton variant='secondary' onClick={redirectToDocumentation}>
            {I18n.t('errors.fallback.documentation')}
          </DomButton>
        </div>
      </div>
    </div>
  )
}

export default FallbackScreen
