import React from 'react'
import I18n from '../../../locales/I18n'

interface ComponentErrorWrapperProps {
  message?: string // Optional error message
  onClickRefresh?: () => void
}

const ComponentErrorWrapper: React.FC<ComponentErrorWrapperProps> = ({
  message,
  onClickRefresh
}) => {
  // Function to refresh the page
  const handleRefresh = () => {
    if (onClickRefresh) {
      onClickRefresh()
    } else {
      window.location.reload()
    }
  }

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h2>{I18n.t('errors.oops')}</h2>
      {message && <p>{message}</p>}
      <button
        onClick={handleRefresh}
        style={{
          padding: '10px 20px',
          backgroundColor: '#f44336',
          color: 'white',
          border: 'none',
          borderRadius: '10px',
          cursor: 'pointer',
          fontSize: '16px'
        }}
      >
        {I18n.t('errors.retry')}
      </button>
    </div>
  )
}

export default ComponentErrorWrapper
