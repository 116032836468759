/**
 * @file Manages Home Tour
 * @author François Thiounn
 */

import * as React from 'react'
import I18n from '../../locales/I18n'
import { OnboardingTooltip } from '@domoscio/domoscio-ui'
import Joyride from 'react-joyride'
import { User } from '../../contexts/parameters/parametersWrapper'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { useDisplayMailModal } from '../../hooks/useDisplayMailModal'
import { useRiseUpClient } from '../../hooks/useRiseUpClient'

interface Props {
  children: React.ReactNode
}

export default function HomeTour({ ...props }: Props) {
  const { isRiseup } = useRiseUpClient()
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)

  const shouldRunTour = () => {
    if (isRiseup) {
      return false
    } else {
      return !(user?.onboarding?.lxp_lock?.home ?? false)
    }
  }
  const runTour = shouldRunTour()

  // 🚨 Wait for closing mail modal before runing tour
  const isDisplayMailModal = useDisplayMailModal()

  const steps = [
    {
      target: '.tour-home-step1',
      title: I18n.t('tour.title.step1'),
      content: I18n.t('tour.home.step1.content'),
      disableBeacon: true,
      placement: 'right' as const
    },
    {
      target: '.tour-home-step2',
      title: I18n.t('tour.title.step2'),
      content: I18n.t('tour.home.step2.content'),
      disableBeacon: true,
      placement: 'bottom' as const
    },
    {
      target: '.tourHomeStep3',
      title: I18n.t('tour.title.step3'),
      content: I18n.t('tour.home.step3.content'),
      disableBeacon: true,
      placement: 'bottom' as const
    }
  ]

  const handleJoyrideCallback = data => {
    const { action, status } = data
    if (status === 'finished' || action === 'reset') {
      if (runTour) {
        userDispatch({
          onboarding: domUtils.updateTour(user.onboarding, 'lxp_lock', { home: true })
        })
      } else {
        userDispatch({ replayTour: { home: false } })
      }
    }
  }

  const isReplay = user?.replayTour?.home ?? false
  return (
    <>
      <Joyride
        run={(runTour || isReplay) && !isDisplayMailModal}
        steps={steps}
        continuous={true}
        showSkipButton={true}
        tooltipComponent={OnboardingTooltip}
        callback={handleJoyrideCallback}
      />
      {props.children}
    </>
  )
}
