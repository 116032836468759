/**
 * @file Manages the methods of the ActionTimeline React Component.
 * @author Matthieu Mugnier
 */

import React, { useState } from 'react'
import ActionElement from './ActionElement'
import { useDomToolkit } from '../../../styles/dom-toolkit'
import { useHomeReviews } from '../../../screens/home/ReviewsContext'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import './ActionTimeline.scss'
/**
 * Renders the ActionTimeline
 *
 * @category Screens
 */
const ActionTimeline = () => {
  const [responsive, setResponsive] = useState(false)
  const classes = useDomToolkit()

  const { reviews } = useHomeReviews()

  React.useEffect(() => {
    const updateDimensions = () => {
      const responsive = window.innerWidth < 1024
      setResponsive(responsive)
    }
    // Call it once on mount to set initial state
    updateDimensions()
    // Add event listener for window resize
    window.addEventListener('resize', updateDimensions)

    // Cleanup on component unmount
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  /**
   * Render the divider by position (left/right)
   */
  const displayDivider = (position: 'left' | 'right') => {
    let containerClass = `dividerHorizontal ${position === 'right' ? 'left50' : 'left0'}`
    containerClass = responsive ? `dividerVertical ` : containerClass
    return (
      <div className={`dividerContainer ${containerClass}`}>
        {Array(4)
          .fill(1)
          .map((_el, i) =>
            responsive ? (
              <span
                key={i}
                className={`${classes.domDividerVertical} ${classes.domFlex1} ${classes.domMarginBottom}`}
              ></span>
            ) : (
              <span
                key={i}
                className={`${classes.domDivider} ${classes.domFlex1} ${
                  i === 0 && classes.domMarginLeft
                } ${i !== 3 && classes.domMarginRight}`}
              ></span>
            )
          )}
      </div>
    )
  }

  const computedReviews = domUtils.computeReviewsForTimeline(reviews.allReviews)

  const dayList = Object.keys(computedReviews)

  return (
    <div className={`ActionTimeline ${classes.domFlex}`}>
      {/* Timeline container */}
      <div
        className={`${classes.domFlex} ${classes.domFlex2} ${responsive && classes.domFlexColumn}`}
      >
        {dayList.map((day, i) => (
          /* Action Element Container */
          <div
            key={i}
            className={`${classes.domFlex1} ${
              responsive ? classes.domFlex : classes.domFlexColumn
            } ${
              !responsive && dayList[0] === day && 'marginNegative40 tour-home-step2'
            } ${responsive && classes.domMarginXLargeBottom}`}
          >
            <div
              className={`${classes.domFlex} ${classes.domFlexMiddle} ${classes.domFlexCenter} ${classes.domPositionRelative}`}
            >
              {/* Divider Left (false for first element) */}
              {!responsive && !(dayList[0] === day) && displayDivider('left')}
              {/* ActionElement display */}
              <ActionElement display='element' day={day} reviews={computedReviews[day]} order={i} />
              {/* Divider Right (false for last element) */}
              {!(dayList[dayList.length - 1] === day) && displayDivider('right')}
            </div>
            {/* ActionElement label */}
            <div
              className={`${classes.domMarginTop} ${classes.domFlexCenter} ${
                classes.domText18
              } ${classes.domTextGreyDk200} ${responsive && classes.domMarginLargeLeft}`}
            >
              <ActionElement display='label' day={day} reviews={computedReviews[day]} order={i} />
            </div>
          </div>
        ))}
        {/* Optional blank space */}
        {Array(4 - dayList.length)
          .fill(0)
          .map((_zero, i) => (
            <div key={i} className={classes.domFlex1}></div>
          ))}
      </div>
      {/* Right blank space */}
      <div className={`${classes.domFlex1}`}></div>
    </div>
  )
}

export default ActionTimeline
