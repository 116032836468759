/* eslint-disable */
import React from 'react'
import { MdOutlineDirectionsRun, MdOutlineLiveHelp, MdWatchLater } from 'react-icons/md'
import { useDomToolkit } from '../../styles/dom-toolkit'
import I18n from '../../locales/I18n'

import '../../styles/LearningSessionResult.scss'
import { useRiseUpClient } from '../../hooks/useRiseUpClient'

type BadgeProps = {
  data: {
    value: string | number
    scale?: string | number
  }
  type:
    | 'answers'
    | 'action'
    | 'reviews'
    | 'pendingReviews'
    | 'nextReviews'
    | 'noReviews'
    | 'pendingMemos'
    | 'nextMemos'
    | 'noMemos'
  label?: string | undefined
  options?: {
    [key: string]: any
  }
}

const reviewsType = [
  'pendingReviews',
  'nextReviews',
  'noReviews',
  'pendingMemos',
  'nextMemos',
  'noMemos'
]

const Icon = ({ id }) => {
  const props = { style: { color: '#edf4fa' } }
  switch (id) {
    case 'answers':
      return <MdOutlineLiveHelp {...props} />
    case 'action':
      return <MdOutlineDirectionsRun {...props} />
    case 'reviews':
      return <MdWatchLater {...props} />
    default:
      return <></>
  }
}

const Badge = ({ data, type, label, options }: BadgeProps) => {
  const { isRiseup } = useRiseUpClient()
  if (isRiseup) {
    type = type.replace('Reviews', 'Memos') as any
  }

  // Custom function specific to nextReviews
  const getNumberOfDays = reviews => {
    const nbDays = reviews?.daysUntilNextReview
    return I18n.t('learning_session_result.badges.numberOfDays', { nbDays: nbDays, count: nbDays })
  }

  // Constants declaration
  const classes = useDomToolkit()
  const family = reviewsType.includes(type) ? 'reviews' : type
  const numberOfDays = options && 'reviews' in options ? getNumberOfDays(options.reviews) : 1

  // Renderers
  const getLabelText = () => {
    if (typeof label === 'undefined') {
      const textbase = I18n.t(
        type === 'nextMemos' ? 'home.memos' : `learning_session_result.badges.${type}`,
        {
          count: data.value
        }
      )
      const additionalText = type.includes('next') ? numberOfDays : ''
      return textbase + additionalText
    } else {
      return label
    }
  }
  return (
    <div
      className={`badge ${family} ${classes.domPositionRelative}  ${classes.domFlex} ${classes.domFlexColumn} ${classes.domFlexMiddle}`}
    >
      <div className={`wrapper ${classes.domFlexPerfect} ${classes.domButtonCircleMedium}`}>
        <div className={`data ${classes.domText50}`}>
          {data.value}
          {data?.scale && <>/{data.scale}</>}
        </div>
        <div
          className={`icon ${classes.domFlex} ${classes.domFlexPerfect} ${classes.domBgGreyLt200} ${classes.domPositionAbsolute} ${family}`}
        >
          <Icon id={family} />
        </div>
      </div>
      <div className={`label ${classes.domTextCenter}`} style={{ width: 150 }}>
        {getLabelText()}
      </div>
    </div>
  )
}

export default Badge
