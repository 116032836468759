/**
 * @file Manages the methods relative to the domToolkit setup in lxp web.
 * @author Matthieu Mugnier
 */

import { createUseStyles } from 'react-jss'
import { domToolkit, domToolkitRJS } from '@domoscio/domoscio-sdk-js'

export const toolkit = {
  ...domToolkit,
  ...domToolkitRJS
}

/**
 * Custom Hooks to use Toolkit with ThemeProvider.
 */
// eslint-disable-next-line
export const useDomToolkit: any = createUseStyles(theme => {
  const injectedTheme = domToolkit.isThemeValid(theme) ? domToolkit.initTheme(theme) : domToolkit
  return {
    ...injectedTheme,
    ...domToolkitRJS,
    ...customTheme(theme)
  }
})

// Overide some toolkit color properties to fit with web version
const customTheme = theme => {
  const primaryKey = Object.keys(theme).find(x => x.includes('Primary')) || ''
  const primary = theme[primaryKey]
  return {
    domProgressPassed: {
      ...domToolkit.domProgressPassed,
      backgroundColor: primary + ' !important'
    }
  }
}

/**
 * Style object : format components style in pretty way like RN StyleSheet.create()
 */

interface TClass {
  // eslint-disable-next-line
  [key: string]: Array<string> | any
}
export const Style = {
  create: (style: TClass) => {
    iterate(style)
    return style
  },
  classes: (array: Array<string>) => array.join(' ')
}

const iterate = (obj: TClass) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] instanceof Array && obj[key].length !== 0) {
      obj[key] = obj[key].join(' ')
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      iterate(obj[key])
    }
  })
}
