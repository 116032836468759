import { FormationProgress, SkillLabels, SkillProgress } from '@domoscio/domoscio-ui'
import { useState } from 'react'
import { useRiseUpClient } from '../../../hooks/useRiseUpClient'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { FormatedMemoryLevelsData, FormatedSkill, SkillProgressType } from './types'
import I18n from '../../../locales/I18n'

interface Props {
  data: FormatedMemoryLevelsData
  filteredSkills?: number[]
}

export const FormationWrapper = ({ data, filteredSkills }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean | number[] | undefined>(filteredSkills)
  const { primaryColor } = useRiseUpClient()

  // calculate course progress based on skill progress
  const getFormationProgress = (skillsDatas: SkillProgressType[]) => {
    const count = skillsDatas?.length
    const courseProgress =
      skillsDatas.reduce((accumulator, currentValue) => accumulator + currentValue.percentage, 0) /
      count
    return Math.floor(courseProgress)
  }

  // memo levels
  const labals = ['days', 'week', 'weeks', 'months'].map(label =>
    I18n.t(`learning_session_result.progress.legend.${label}`)
  )

  // on result screen show only studied skills
  const getSkills = (skills: FormatedSkill[]) => {
    if (filteredSkills) {
      return skills.filter(skill => filteredSkills.includes(Number(skill.id)))
    } else {
      return skills
    }
  }

  return (
    <div className={`formationWrapper ${data.isActive ? 'default' : 'disabledCourse'}`}>
      <div className='firstLine2' onClick={() => setIsOpen(filteredSkills ? true : !isOpen)}>
        <FormationProgress
          skillCount={data.skillCount}
          name={data.formationName}
          date={data.date}
          percentage={Number(getFormationProgress(data.skills))}
          primaryColor={data.isActive ? primaryColor : '#898AA0'}
        />
        {!filteredSkills && (
          <span className='dropIcon'>
            {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </span>
        )}
      </div>
      {isOpen && (
        <div className='skillsSection'>
          <SkillLabels labels={labals}>
            {getSkills(data.skills).map((el, i) => (
              <div key={i}>
                <SkillProgress
                  {...el}
                  helpText={I18n.t('home.memo_inactive_tooltip')}
                  primaryColor={primaryColor}
                  isLastElem={i === data.skills.length - 1}
                />
              </div>
            ))}
          </SkillLabels>
        </div>
      )}
    </div>
  )
}
