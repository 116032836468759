import { Client, User } from '../contexts/parameters/parametersWrapper'
import { useContext } from 'react'
import { useRiseUpClient } from './useRiseUpClient'

export const useDisplayMailModal = () => {
  const client = useContext(Client.State)
  const user = useContext(User.State)
  const { isRiseup } = useRiseUpClient()

  // 👇 conditions to display email modal
  const mailsAuthorized = client.mqb_mailer_daily_reviews
  const userMail = user?.email || false
  const isUserSeenMailModal = user.onboarding?.lxp_lock?.mail_screen || false

  const isDisplayMailModal = mailsAuthorized && !isUserSeenMailModal && !userMail && !isRiseup
  return isDisplayMailModal ?? false
}
