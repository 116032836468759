import styles from './NoChallengeCard.module.css'
import React from 'react'

// mini component to create blank areas
const BlankPlaceholder = ({ width, height, marginBottom, loading }) => {
  return (
    <div
      className={loading ? styles.linearBackground : ''}
      style={{ width, height, marginBottom }}
    ></div>
  )
}

const NoChallengeCard = ({ loading }) => {
  // blank placeholder properties [width, height, marginBottom]
  const lines = [
    ['50%', '8%', '5%'],
    ['94%', '16%', '8%'],
    ['80%', '8%', '5%'],
    ['80%', '8%', '5%'],
    ['20%', '8%', '7%'],
    ['94%', '14%', '5%']
  ]

  return (
    <div
      className={`${styles.noChallengeCard} 
    `}
    >
      {lines.map((elem, index) => (
        <BlankPlaceholder
          key={index}
          width={elem[0]}
          height={elem[1]}
          marginBottom={elem[2]}
          loading={loading}
        />
      ))}
    </div>
  )
}

export default NoChallengeCard
