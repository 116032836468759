import React, { useEffect, useState } from 'react'
import I18n from '../../locales/I18n'
import { MdOutlineUnsubscribe } from 'react-icons/md'
import './Unsubscribe.scss'
import { domoscioAccess } from '../../api/domoscio_access'
import { getStorage } from '../../storage'
import { Loader } from '@domoscio/domoscio-ui'
const Unsubscribe = () => {
  const [status, setStatus] = useState('loading')
  const storage = getStorage()
  useEffect(() => {
    domoscioAccess
      .updateUserParameter({ mail_notification: false })
      .then(() => setStatus('success'))
      .catch(err => {
        console.log(err)
        setStatus('error')
      })
      .finally(() => storage.clear())
    // eslint-disable-next-line
  }, [])

  if (status === 'loading') {
    return <Loader />
  }
  return (
    <div className='unsubscribe'>
      {status === 'error' ? (
        <h2>{I18n.t('lxp_hub.settings_screen.error_generic')}</h2>
      ) : (
        <>
          <MdOutlineUnsubscribe size={100} />
          <h2>{I18n.t('unsubscribe.title')}</h2>
          <p>{I18n.t('unsubscribe.content')}</p>
        </>
      )}
    </div>
  )
}

export default Unsubscribe
