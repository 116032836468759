import { useContext } from 'react'
import { Client } from '../contexts/parameters/parametersWrapper'
interface ReturnedValue {
  isRiseup: boolean
  primaryColor: string
}

export const useRiseUpClient = (): ReturnedValue => {
  const client = useContext(Client.State)
  if (!client) {
    throw new Error('useRiseUpClient must be used within an Parameters provider')
  }
  return {
    isRiseup: client.riseup_client ?? false,
    primaryColor: client.mqb_primary_color || '#005eb8'
  }
}
