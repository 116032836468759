/**
 * @file Contains the content xml QTI fetch http call.
 * @author Matthieu Mugnier
 */

import { domoscioAccess } from '../domoscio_access'

/**
 * Get the content XML QTI and convert to json result.
 * @category API
 * @async
 * @param {string} url - The url of the request content.
 * @return {Promise<object>} - An object contains the content QTI info.
 */

export const getCellTest = async (url: string) => {
  return await domoscioAccess.getQti({
    cell_test_url: url
  })
}
