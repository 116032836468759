import React from 'react'
import I18n from '../../locales/I18n'

export const HomeNavRu = () => {
  return (
    <div className='homeNav_ru'>
      <div className='memoTitle'>{I18n.t('home.memos.other')}</div>
    </div>
  )
}
