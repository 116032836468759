import React, { useEffect, useRef, useState } from 'react'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { Badge, HtmlToReact, OneLineTruncatedText } from '@domoscio/domoscio-ui'
import I18n from '../../locales/I18n'
import { useRiseUpClient } from '../../hooks/useRiseUpClient'
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md'
import './CorrectionCardRU.scss'
import { Result } from '../../screens/learning_session/LearningSession'

type Props = {
  result: Result
  identifier: number
  onClick: (index: number) => void
}

const CorrectionCard = ({ result, identifier, onClick }: Props) => {
  // Props
  const { input, props } = result
  const handleClick = () => onClick && onClick(identifier)
  const { isRiseup } = useRiseUpClient()
  const [isTruncated, setIsTruncated] = useState({ skillName: false, courseName: false })

  // Constants
  const status = input?.score === 100 ? 'success' : 'danger'
  const content = domUtils.cropText(
    domUtils.getTextFromContent(
      // @ts-expect-error i know what i do 😀
      props.type === 'holes' ? props.body?.prompt : props?.statement || props?.heading
    ),
    100
  )
  const textRefSkillName = useRef<HTMLDivElement | null>(null)
  const textRefCourseName = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (textRefSkillName.current) {
      setIsTruncated(prev => ({
        ...prev,
        skillName:
          (textRefSkillName.current?.scrollWidth || 0) >
          (textRefSkillName.current?.clientWidth || 0)
      }))
    }
    if (textRefCourseName.current) {
      setIsTruncated(prev => ({
        ...prev,
        courseName:
          (textRefCourseName.current?.scrollWidth || 0) >
          (textRefCourseName.current?.clientWidth || 0)
      }))
    }
  }, [])

  return isRiseup ? (
    <div className={`correctionCardRU ${status}`} onClick={handleClick}>
      <div className='section1'>
        <OneLineTruncatedText content={isTruncated.courseName ? result.courseName || '' : null}>
          <div ref={textRefCourseName} className='courseName'>
            {result.courseName}
          </div>
        </OneLineTruncatedText>
        <div>
          <span className='svgCircle'>
            {input?.score === 100 ? <MdOutlineCheck /> : <MdOutlineClose />}
          </span>
        </div>
      </div>
      <div className='section2'>
        <OneLineTruncatedText content={isTruncated.skillName ? props.concept : null}>
          <div ref={textRefSkillName} className='skillName'>
            {props.concept}
          </div>
        </OneLineTruncatedText>

        <div className='correction_content'>
          <HtmlToReact html={content} />
        </div>
      </div>
    </div>
  ) : (
    <div className={`correctionCard ${status}`} onClick={handleClick}>
      <div className='row'>
        <Badge color={status} size='sm'>
          {I18n.t('learning_session_result.answer')}
        </Badge>
        <Badge size='sm'>{props?.tag}</Badge>
      </div>
      <span className='title' title={props?.concept}>
        {props?.concept || 'X'}
      </span>
      <div className='content'>{content}</div>
    </div>
  )
}

export default CorrectionCard
