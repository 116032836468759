import React, { useState } from 'react'
import { FaCalendarPlus } from 'react-icons/fa'
import './AddToCalendar.scss' // Custom CSS for styling
import OutsideClickHandler from 'react-outside-click-handler'
import { OneLineTruncatedText } from '@domoscio/domoscio-ui'
import I18n from '../../../locales/I18n'
import { createEvent, setEvent, TCalendar } from './utils'
import { useRiseUpClient } from '../../../hooks/useRiseUpClient'
import { getMailIcon } from './components/MailIcons'
import { StudentReminders } from '../../../types/global'
import { domoscioAccess } from '../../../api/domoscio_access'

interface Props {
  reviews: StudentReminders[]
  date: string
}

const AddToCalendarDropdown = ({ reviews, date }: Props) => {
  // States to handle visibilities
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const { isRiseup, primaryColor } = useRiseUpClient()

  // Toggle dropdown visibility
  const toggleDropdown = () => setDropdownVisible(!dropdownVisible)

  const calendarNames = ['apple', 'google', 'outlook'] as const

  const handleClickCalendar = (calendarName: TCalendar) => {
    createEvent(setEvent(date, reviews.length, isRiseup), calendarName)
    const verbForKpi =
      'StudentDownloadCalendar' +
      String(calendarName).charAt(0).toUpperCase() +
      String(calendarName).slice(1)
    domoscioAccess.postKpiLog(verbForKpi)
  }
  if (reviews.length === 0) {
    return null
  }
  return (
    <div className='AddToCalendarContainer'>
      <OutsideClickHandler
        onOutsideClick={() => {
          setDropdownVisible(false)
        }}
      >
        <div className='calendar-dropdown'>
          {/* Calendar icon button */}
          <OneLineTruncatedText
            position='top'
            content={I18n.t(`home.add_to_calendar.tooltip${isRiseup ? 'v2' : ''}`)}
          >
            <button className='calendar-button' onClick={toggleDropdown}>
              <FaCalendarPlus size={18} color={primaryColor} />
            </button>
          </OneLineTruncatedText>
          <div className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`}>
            {calendarNames.map((elem, index) => (
              <button
                key={index}
                onClick={() => handleClickCalendar(elem)}
                className='dropdown-item'
              >
                {getMailIcon(elem)}
                {elem}
              </button>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default AddToCalendarDropdown
