import React, { useState } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

type Props = {
  children: React.ReactNode
  startAt?: number
}

const styles = {
  container: { overflow: 'hidden', height: '100%' },
  slide: { display: 'flex', height: '100%' }
}

const Slideshow: React.FC<Props> = ({ children, startAt = 0 }) => {
  const [currentSlide, setCurrentSlide] = useState(startAt)

  // Constants
  const canPrevious = currentSlide > 0
  const canNext = currentSlide < React.Children.count(children) - 1

  const handlePrevious = () => {
    if (canPrevious) {
      setCurrentSlide(currentSlide - 1)
    }
  }

  const handleNext = () => {
    if (canNext) {
      setCurrentSlide(currentSlide + 1)
    }
  }

  return (
    <div className='Slideshow'>
      {canPrevious && (
        <div className='previous'>
          <MdChevronLeft size={48} onClick={handlePrevious} />
        </div>
      )}
      <div style={styles.container}>
        {React.Children.map(children, (child, index) => {
          return (
            <div
              style={{
                ...styles.slide,
                width: '100%',
                transform: `translate(${(index - currentSlide) * 100}%, -${index * 100}%)`,
                transition: 'transform 0.5s ease-out'
              }}
            >
              <div key={index} className='slide-item' style={{ width: '100%' }}>
                {child}
              </div>
            </div>
          )
        })}
      </div>
      {canNext && (
        <div className='next'>
          <MdChevronRight size={48} onClick={handleNext} />
        </div>
      )}
    </div>
  )
}

export default Slideshow
