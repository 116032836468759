/**
 * @file Manages the methods of the stateless AppLoader component.
 * @author Matthieu Mugnier
 */

import React, { useEffect, useState } from 'react'

/**
 * A component used to display a loading while some processes are loading.
 */

interface Props {
  minimumLoadingTime?: number
  children: React.ReactNode
  loadingComponent: React.ReactNode
  mandatoryProcesses: {
    name: string
    isReady: boolean
  }[]
}
function AppLoader(props: Props) {
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(
    (props.minimumLoadingTime || 0) <= 0
  )

  // Handle potential minimum duration
  useEffect(() => {
    if (props.minimumLoadingTime) {
      setTimeout(() => setMinimumDurationPassed(true), props.minimumLoadingTime)
    }
  }, [props.minimumLoadingTime])

  return (
    <>
      {props.mandatoryProcesses.every(process => process.isReady) && minimumDurationPassed
        ? props.children
        : props.loadingComponent}
    </>
  )
}

export default AppLoader
