import React, { Dispatch as ReactDispatch } from 'react'
import { ClientParameters } from '../../types/parameters'

// Create Contexts
const State = React.createContext<Partial<ClientParameters>>({})
const Dispatch = React.createContext<ReactDispatch<Partial<ClientParameters>>>(T => T)

// Initial State
const init: Partial<ClientParameters> = {} // You can define the initial state values here if needed

// Reducer
const reducer = (state: Partial<ClientParameters>, nextState: Partial<ClientParameters>) => {
  const client = {
    ...state,
    ...nextState
  } as Partial<ClientParameters>
  return client
}

// Provider component
interface ProviderProps {
  children: React.ReactNode
}

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, init)

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  )
}

// Export the Client context
export const Client = {
  State,
  Dispatch,
  Provider
}
