import I18n from '../../../locales/I18n'

interface TEvent {
  title: string
  description: string
  start: string
  end: string
}

export type TCalendar = 'apple' | 'google' | 'outlook'

export const createEvent = (event: TEvent, type: TCalendar) => {
  if (type === 'apple') {
    const appleEventUrl = `data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0D%0AVERSION:2.0%0D%0ABEGIN:VEVENT%0D%0ASUMMARY:${encodeURIComponent(
      event.title
    )}%0D%0ADESCRIPTION:${encodeURIComponent(
      event.description
    )}%0D%0ADTSTART;VALUE=DATE:${formatDate(event.start)}%0D%0ADTEND;VALUE=DATE:${formatDate(
      event.end
    )}%0D%0AEND:VEVENT%0D%0AEND:VCALENDAR`
    const link = document.createElement('a')
    link.href = appleEventUrl
    link.download = 'apple_event.ics'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } else if (type === 'google') {
    const googleEventUrl = `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
      event.title
    )}&details=${encodeURIComponent(event.description)}&dates=${formatDate(event.start)}/${formatDate(event.end)}`
    window.open(googleEventUrl, '_blank')
  } else {
    const outlookEventUrl = `https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent&subject=${encodeURIComponent(
      event.title
    )}&body=${encodeURIComponent(event.description)}&startdt=${event.start}&enddt=${event.end}&allday=true`
    window.open(outlookEventUrl, '_blank')
  }
}

export function getNextDay(dateStr: string): string {
  // Convert the input string into a Date object
  const date = new Date(dateStr)
  // Add one day
  date.setDate(date.getDate() + 1)
  // Convert back to 'YYYY-MM-DD' format and return
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

// formates date to "YYYYMMDD"
export function formatDate(date: string): string {
  const d = new Date(date)
  const year = d.getUTCFullYear()
  const month = ('0' + (d.getUTCMonth() + 1)).slice(-2)
  const day = ('0' + d.getUTCDate()).slice(-2)
  return `${year}${month}${day}`
}

export const setEvent = (date: string, nbrActions: number, isRiseup: boolean) => ({
  title: I18n.t('home.add_to_calendar.event_title', { nbr_actions: nbrActions }),
  description: I18n.t(`home.add_to_calendar.event_desc${isRiseup ? 'v2' : ''}`, {
    nbr_actions: nbrActions,
    count: nbrActions
  }),
  start: date,
  end: getNextDay(date)
})
