import React, { useEffect, useState } from 'react'
import { getAverageMemoryLevel } from '../../../core/fetchObjectiveDatas'
import { Loader } from '@domoscio/domoscio-ui'
import ProgressData from '../../../components/home/progress/ProgressData'
import './ProgressRiseUpTsr.scss'
import { MemoLevels } from '../../../components/home/progress/types'
import { Result } from '../../learning_session/LearningSession'
import { ActionResult } from '../../learning_session/LearningAction'
interface Props {
  studiedObjectives: number[]
  results: Array<Result | ActionResult>
}
export const ProgressRiseUpTsr = ({ studiedObjectives, results }: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<MemoLevels | null>(null)

  const studiedSkills = results.map(result => result?.content?.review?.knowledge_node_id)

  useEffect(() => {
    getAverageMemoryLevel(studiedObjectives, true).then(data => {
      setData(data.objectives)
      setIsLoading(false)
    })
    // eslint-disable-next-line
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className='progressRiseUpTsr'>
      <ProgressData
        progressData={data ? data : undefined}
        isTsr={true}
        filteredSkills={studiedSkills}
      />
    </div>
  )
}
