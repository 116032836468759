import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Loader, Question } from '@domoscio/domoscio-ui'
import { useLearningSession } from '../../hooks/useLearningSession'
import { closeMicroAction } from '../../api/adaptive_engine/events'
import { useProgress } from '../../contexts/progressData/progressData'
import '../../styles/LearningSession.scss'
import { useRiseUpClient } from '../../hooks/useRiseUpClient'
import { StudentReminders } from '../../types/global'

const qti2js = (action: Action) => Question.converter.qti2js((action as { qti: string }).qti)

/**
 * @category Screens
 * /learning_actions
 */

interface Action {
  review: StudentReminders
  title: string
  description: string
  dayLeft: number
  qti: string
}
interface LocationState {
  action: Action
}

export interface ActionResult {
  // eslint-disable-next-line
  input: any
  content: {
    review: StudentReminders
    title: string
    description: string
    dayLeft: number
    qti: string
  }
  props: ReturnType<typeof qti2js>
}

const LearningAction = () => {
  // State initialization
  const [activeIndex] = useState(0)
  const [score, setScore] = useState<undefined | number>(undefined)
  const [isProcessing, setIsProcessing] = useState(false)
  const [result, setResult] = useState<ActionResult>()

  // Hooks
  const navigate = useNavigate()
  const location = useLocation()
  const { learningSession, closeLearningSession } = useLearningSession()
  const { progress } = useProgress()
  const { isRiseup } = useRiseUpClient()

  // Constants
  const { action } = location.state as LocationState

  const getActionProps = () => ({
    ...qti2js(action),
    options: { status: 'accepted' as const }
  })

  // Format action to fit post event process
  const contentFromAction = (action: Action) => {
    return {
      ...action,
      id: action.review.content_id,
      type: action.review.content_type
    }
  }

  // Exit screen function
  const exit = (isAnswered?: boolean) => {
    closeLearningSession()
    const isFailed = result?.input?.answers?.includes('failed') // case challenge not performed => return home page
    if (isAnswered && !isFailed) {
      navigate('/learning_actions/result', {
        state: {
          results: [result],
          studiedObjectives: [getObjectiveIdOfQuestion()]
        }
      })
    } else {
      navigate('/')
    }
  }

  const getObjectiveIdOfQuestion = () => {
    if (!isRiseup) {
      return undefined
    }
    const objectiveIds = action?.review?.objective_ids || []
    return progress ? objectiveIds.filter(id => progress[id])[0] : undefined
  }

  // <Question.onSubmit />
  // eslint-disable-next-line
  const handleSubmit = async (answer: any) => {
    if (!learningSession) {
      return null
    }
    setIsProcessing(true)
    const score = answer.answers.includes('difficult')

    setScore(score ? 100 : undefined)

    // Store results
    const result = { input: answer, content: action, props: qti2js(action) }
    closeMicroAction({
      input: answer,
      content: contentFromAction(action),
      learningSession: learningSession
    }).then(() => {
      setResult(result)
      setIsProcessing(false)
    })
  }

  // Renderers
  return !action ? null : (
    <div className='LearningSession Action' data-index={activeIndex}>
      <div className='actionHeader'>
        <Question.QuizHeader onCancel={() => exit(false)} title={getActionProps().concept} />
      </div>
      <section className='container'>
        {learningSession === null ? (
          <Loader />
        ) : (
          <Question
            {...getActionProps()}
            onSubmit={handleSubmit}
            correctionEnabled={true}
            isChallengeValidation={true}
            correctionScore={score}
            isCorrectionMode={score ? true : false}
            onNextAction={() => exit(true)}
            awaitingCallback={isProcessing}
          />
        )}
      </section>
    </div>
  )
}

export default LearningAction
