/**
 * @file Manages the methods relative to the user jwt -> update Item from the sessionStorage.
 * @author Matthieu Mugnier
 */

import jwtDecode from 'jwt-decode'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { getStorage } from './index'
import { StudentParameters } from '../types/parameters'
import { TAllParameters } from '../core/Initializers'

export function updateUserJWT(user: Partial<StudentParameters>) {
  // Get storage from browser context (try if sessionStorage is available)
  const storage = getStorage()

  const jwt = storage.getItem('jwt')
  const accessTkn = storage.getItem('accesstoken')
  if (jwt !== null) {
    const parameters: TAllParameters = jwtDecode(jwt)
    parameters.student_parameters = {
      ...parameters.student_parameters,
      ...user
    }
    storage.setItem('jwt', domUtils.encodeJWT(parameters, accessTkn))
  }
}
