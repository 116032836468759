import React, { useState } from 'react'
import './ProgressData.scss'
import { useProgress } from '../../../contexts/progressData/progressData'
import I18n from '../../../locales/I18n'
import { FormationWrapper } from './components'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { FormatedMemoryLevelsData, MemoLevels } from './types'

interface Props {
  isTsr?: boolean // isTsr condition is used only on result result screen
  progressData?: MemoLevels
  filteredSkills?: number[]
}

const ProgressData = ({ isTsr, progressData, filteredSkills }: Props) => {
  const [openSections, setOpenSections] = useState({ 1: true, 2: true }) // 1:active memo section 2: inactive memo section
  const { progress, objectives } = useProgress() // get progress data from context

  const actualProgressData = progressData || progress // if progress data is passed as prop use this if not use progress in context
  if (!actualProgressData) {
    return null
  }

  // get date of creation of objective student
  const getDate = (id: number) => {
    const objective = objectives?.find(el => el.objective_id === id)

    if (objective) {
      // Create a new Date object from the input timestamp
      const date = new Date(objective.created_at)

      // Extract the day, month, and year
      const day = String(date.getUTCDate()).padStart(2, '0')
      const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-based
      const year = date.getUTCFullYear()

      // Format the date as "dd/mm/yyyy"
      return `${day}/${month}/${year}`
    } else {
      return '00.00.00'
    }
  }

  const formatedProgresData: Array<FormatedMemoryLevelsData> = Object.keys(actualProgressData).map(
    el => {
      const data = actualProgressData[el]
      const skillsDatas = data.knowledge_nodes
      return {
        date: getDate(Number(el)),
        skillCount: data.nb_knowledge_nodes,
        isActive: data.active,
        formationName: data.name,
        skills: Object.keys(skillsDatas).map(skillId => {
          const skill = skillsDatas[skillId]
          return {
            percentage: Math.floor(Number((skill.average_memory_level / 3.0) * 100)),
            isActive: skill.active,
            name: skill.name,
            id: skillId
          }
        })
      }
    }
  )

  const activeObjectives = formatedProgresData.filter(elem => elem?.isActive) // ids of active objectives
  const disabledObjective = formatedProgresData.filter(elem => !elem?.isActive) // ids of inactive objectives

  const sections = [] as Array<{ name: string; data: typeof activeObjectives }>

  if (activeObjectives.length > 0) {
    const section = {
      name: isTsr
        ? I18n.t('home.progress')
        : I18n.t('home.memo_active') + ` (${activeObjectives.length})`,
      data: activeObjectives
    }
    sections.push(section)
  }
  // if inactive objective exist, create inactive section
  if (disabledObjective.length > 0 && !isTsr) {
    const section = {
      name: I18n.t('home.memo_inactive') + ` (${disabledObjective.length})`,
      data: disabledObjective
    }
    sections.push(section)
  }

  return (
    <div className='progressData'>
      {sections.map((section, index) => {
        return (
          <div className='section' key={index}>
            <div
              className='sectionTitle'
              onClick={() => setOpenSections(prev => ({ ...prev, [index]: !prev[index] }))}
            >
              {section.name}{' '}
              {!isTsr && (
                <span>{openSections[index] ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}</span>
              )}
            </div>
            {!openSections[index] &&
              section.data.map((obj, i) => (
                <FormationWrapper data={obj} key={i} filteredSkills={filteredSkills} />
              ))}
          </div>
        )
      })}
    </div>
  )
}

export default ProgressData
