/**
 * @file Manages processed data on load with useEffect hook.
 * @author Matthieu Mugnier
 */

import { useCallback, useEffect, useState } from 'react'
import { StudentParameters } from '../types/parameters'
interface User extends Partial<StudentParameters> {
  isRiseUpUser?: boolean
}
export function useInitialization(process: User) {
  const setInitialization = useCallback(async param => {
    setInit(prev => ({
      ...prev,
      parameters: param
    }))
  }, [])

  // State
  const [init, setInit] = useState({
    parameters: {},
    setInitialization
  })

  // Check data on load.
  useEffect(() => {
    async function initializeState() {
      const datas = process
      setInit(prev => ({
        ...prev,
        parameters: datas
      }))
    }
    initializeState()
  }, [process])

  return init
}
