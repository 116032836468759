/* eslint-disable */
export type Payload = { [key: string]: any }

const xAPIVerb = (verb: string) => {
  return {
    verb: {
      id: `http://adlnet.gov/expapi/verbs/${verb}`
    }
  }
}

// MicroAction 'Pending'
const actionDeclined = xAPIVerb('declined')
const actionAccepted = {
  ...xAPIVerb('accepted'),
  result: {
    delay_for_action: 7
  }
}

// MicroAction 'Accepted'
const actionPerformed = (score: number) => {
  return {
    ...xAPIVerb('performed'),
    result: {
      score: {
        scaled: 1
      },
      success: true
    },
    performed_with_difficulty: score === 0
  }
}

// Microaction accepted but failed
const actionFailed = () => {
  return {
    ...xAPIVerb('failed'),
    result: {}
  }
}

export const payloadOptions = (input: any, content: any, closeAction: boolean) => {
  if (content.type === 'ContentAction') {
    if (closeAction) {
      const isFailed = input?.answers?.includes('failed') // case challenge not performed
      return isFailed ? actionFailed() : actionPerformed(input.score)
    } else {
      return input.score === 0 ? actionDeclined : actionAccepted
    }
  } else {
    return {}
  }
}
