/* eslint-disable */
// @ts-nocheck

// Alternative storage method used when sessionStorage is unavailable
const customStorage = {
  setItem: (key: string, datas: string) => {
    const init = 'customStorage' in window
    if (!init) {
      window.customStorage = {}
    }
    window.customStorage[key] = datas
    return null
  },
  getItem: (key: string) => {
    if ('customStorage' in window && key in window.customStorage) {
      return window.customStorage[key]
    } else {
      return null
    }
  },
  keys: () => {
    if ('customStorage' in window) {
      return Object.keys(window.customStorage)
    } else {
      return null
    }
  },
  clear: () => {
    delete window.customStorage
  }
}

export const getStorage = () => {
  try {
    return sessionStorage || {}
    // eslint-disable-next-line
  } catch (e) {
    return customStorage || {}
  }
}
