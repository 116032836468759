import React from 'react'
import I18n from '../../../locales/I18n'
interface Props {
  height?: string
}
const ReturnToHomeButton = ({ height = '100vh' }: Props) => {
  const handleReturnHome = () => {
    window.location.href = '/'
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height
      }}
    >
      <button onClick={handleReturnHome} style={buttonStyle}>
        {I18n.t('errors.root')}
      </button>
    </div>
  )
}

// Optional: You can define styles here or import them from a CSS file
const buttonStyle: React.CSSProperties = {
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  borderRadius: '10px'
}

export default ReturnToHomeButton
