/**
 * @file Display Content Unavailable Error screen
 * @author Théo Bocquelet
 */

import * as React from 'react'
import { useDomToolkit } from '../../../styles/dom-toolkit'
import { ReactComponent as ContentUnavailable } from '../../../assets/errors/content_unavailable.svg'
import { DomButton } from '@domoscio/domoscio-ui'
import './errors.css'
import I18n from '../../../locales/I18n'

export interface ContentUnavailableErrorScreenProps {
  children?: React.ReactNode | string | null
  fill: string
  support?: string
}

/**
 * Renders the Content Unavailable Error Screen
 *
 * @category Screens
 */
function ContentUnavailableErrorScreen({
  children,
  fill = '#005eb8',
  support = 'support@domoscio.com'
}: ContentUnavailableErrorScreenProps) {
  const classes = useDomToolkit()

  return (
    <div
      className={`${classes.domFlexColumn} ${classes.domFlexPerfect} ${classes.domTextPrimary} ${classes.domTextBold} ${classes.domHeight100}`}
    >
      {React.isValidElement(children) || (typeof children === 'string' && children !== '') ? (
        children
      ) : (
        <>
          <div
            className={`${classes.domMarginLargeTop} ${classes.domTextBold} ${classes.domTextCenter}`}
          >
            {I18n.t('errors.content_unavailable')}
          </div>
          <div className={`${classes.domFlexPerfect} ${classes.domMargin} ${classes.domTextBold}`}>
            <DomButton
              variant='secondary'
              onClick={() => {
                window.location.href = 'mailto:' + support
              }}
            >
              {I18n.t('errors.support')}
            </DomButton>
          </div>
        </>
      )}
      <ContentUnavailable fill={fill} className={`${classes.domHeight70}`} />
    </div>
  )
}

export default ContentUnavailableErrorScreen
